import React from 'react';
import { classes } from '@Components/ui/utils';

const TextInput = ({
  input, type, placeholder = '', label = '', unitLabel = '', helpText = '',
  width = null, rows = null, disabled = false, labelHint = '', style = null,
  autoFocus = false, readOnly = false, className = null, containerClassName = null,
  meta: { touched, error, warning }
}) => {
  const classNames = classes({
    'form-group': true,
    'has-error': touched && error,
    [containerClassName]: containerClassName
  });
  const classListInput = classes({
    'form-control': true,
    [className]: className
  });
  return (
    <div className={classNames} style={style}>
      {label && (
      <label className={disabled ? 'control-label text-muted' : 'control-label'} htmlFor={input.name}>
        {label} {labelHint && <span className="control-label-hint">{labelHint}</span>}
        {warning && (
        <i
          className="fa fa-fw fa-info-circle text-highlight-warn"
          title={warning}
        />
        )}
      </label>
      )}
      <div className="form-control-container" style={{ width }}>
        {unitLabel && (
          <label className="form-control-label">{unitLabel}</label>
        )}
        {rows ? (
          <textarea
            className={classListInput}
            placeholder={placeholder}
            id={input.name}
            disabled={disabled}
            autoFocus={autoFocus}
            readOnly={readOnly}
            rows={rows}
            {...input}
          />
        ) : (
          <input
            type={type || 'text'}
            className={classListInput}
            placeholder={placeholder}
            id={input.name}
            disabled={disabled}
            autoFocus={autoFocus}
            autoComplete="off"
            readOnly={readOnly}
            {...input}
          />
        )}
      </div>
      {touched && error && <span className="help-block">{error}</span>}
      {helpText && (
        <span className="form-control-description">{helpText}</span>
      )}
    </div>
  );
};

export default TextInput;
