import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bkfModals } from '@State/bkf/constants';
import { toggleBKFModal } from '@State/bkf/actions';
import Button from '@Components/ui/button';
import Popover from '@Components/ui/popover';
import ConfirmBlock from '@Components/ui/confirm-block';
import { getOrgWideCustomerDb } from '@State/selectors';
import { deleteCustomer, fetchCustomerLogEntries } from '@State/customer-actions';
import { navigate, getSectionUrl } from '@Utils/navigate';
import { DialogTitleBtn } from '@Components/dialogs/dialog-styles';
import { CustomerType, getCustomerHeading, getCustomerTypeIcon } from '@Utils/customer-util';
import { txt } from '@Utils/i18n-util';
import msg from './customer-detail-header.msg';

const CustomerDetailHeader = ({
  customer, onCloseForm, modalView = false, routeParams
}) => {
  const dispatch = useDispatch();
  const orgWideCustomerDb = useSelector(getOrgWideCustomerDb);
  const locationNames = useSelector(state => state.locationNames);
  const id = customer?.id || customer?.customerId;

  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const mounted = true;

  const handleClose = () => {
    if (mounted) {
      setShowConfirmDelete(false);
    }
  };

  const onShowSendSms = () => {
    dispatch(toggleBKFModal(bkfModals.sendSms, true, {
      customerIds: [id],
      onSmsSent: refreshLogEntries
    }));
  };

  const refreshLogEntries = () => {
    dispatch(fetchCustomerLogEntries(id, true));
  };

  const confirmDelete = async () => {
    await dispatch(deleteCustomer(id));
    handleClose();
    return navigate(getSectionUrl(routeParams, 'customers'));
  };

  const renderPopupBody = (
    <div className="Popover-dialog-sm button-wrapper">
      <ConfirmBlock
        title={txt(msg.confirmDelete)}
        onCancel={() => setShowConfirmDelete(false)}
        onConfirm={confirmDelete}
      />
    </div>
  );

  const { customerType, officialIdNo, name } = customer || {};
  const isVehicle = customerType === CustomerType.Vehicle;
  const showSubHeader = isVehicle && officialIdNo && name;

  return (
    <div className="customer-details-header">
      <i className={`${getCustomerTypeIcon(customer?.customerType)} heading-icon`} />
      <div className="customer-heading">
        <h3 className="mt0 mb0">
          {getCustomerHeading(customer)}
        </h3>
        {showSubHeader && (
          <h5 className="customer-vehicle-heading">{name}</h5>
        )}
        {orgWideCustomerDb && (
          <div><i className="far fa-location-dot mr1" />{locationNames.get(customer?.locationId)}</div>
        )}
        <div className="text-muted">#{id}</div>
      </div>
      {modalView ? (
        <div className="pull-right">
          <DialogTitleBtn onClick={onCloseForm}>{txt(msg.btnClose)}</DialogTitleBtn>
        </div>
      ) : (
        <div className="pull-right">
          {customer?.phoneNumber && (
            <Button gray small marginRight onClick={onShowSendSms}>
              {txt(msg.btnSendSms)}
            </Button>
          )}
          <Popover
            preferPlace="below"
            isOpen={showConfirmDelete}
            onOuterAction={handleClose}
            body={renderPopupBody}
            className="Popover-big-radius"
          >
            <Button outlineDanger small onClick={() => setShowConfirmDelete(!showConfirmDelete)}>
              {txt(msg.btnDelete)}
            </Button>
          </Popover>
        </div>
      )}
    </div>
  );
};

export default CustomerDetailHeader;
