import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import get from 'lodash/get';
import last from 'lodash/last';
import first from 'lodash/first';
import clone from 'lodash/clone';
import moment from 'moment';
import {
  HOURS_MINUTES_SECONDS, DEFAULT_TIME_START, DEFAULT_BREAK_TIME_START, DEFAULT_BREAK_TIME_END
} from '@Utils/time-constants';

import Popover from '@Components/ui/popover';
import { colors } from '@Components/ui/styled/variables';
import { Text } from '@Components/ui/styled/main';
import TimePickerRow from '@Components/inputs/time-picker-row';

import { addTime, getTime, getMinutesDiff } from '@Utils/time-util';
import { DialogButtonsHorizontal, DialogButton } from '@Components/dialogs/dialog-styles';

const AddBreakLink = styled(Text)`
  display: inline-block;
  cursor: pointer;
  line-height: 15px;
  font-weight: 600;
  color: ${colors.textMuted};
  margin-top: 16px;
  margin-bottom: 6px;
  i {
    margin-right: 5px;
  }
`;

const ScheduleItemTimePopover = ({
  onSave, onClose, day, blocks, week, isNew = false, children, ...props
}) => {
  const blockLast = last(blocks);
  const blockFirst = first(blocks);

  const [start, setStart] = useState({ time: getTime(get(blockFirst, 'start', DEFAULT_TIME_START)) });
  const [end, setEnd] = useState({ time: getTime(get(blockLast, 'end', DEFAULT_TIME_START)) });

  const getBreaksBlocks = () => {
    const breaksBlocks = [];
    blocks && blocks.forEach((block, index) => {
      const { end } = block;
      const nextBlock = blocks[index + 1];
      if (nextBlock && nextBlock.start && getMinutesDiff(end, nextBlock.start) < 0) {
        breaksBlocks.push([{
          labelName: 'Rast',
          time: end,
          timeConfig: defTimeConfig
        },
        {
          removeText: 'Ta bort',
          time: nextBlock.start,
          timeConfig: defTimeConfig
        }]);
      }
    });
    return breaksBlocks;
  };

  const [breaksData, setBreaksData] = useState([]);

  useEffect(() => {
    setBreaksData(getBreaksBlocks());
  }, [blocks]);

  const handleClose = () => {
    setBreaksData(getBreaksBlocks());
    onClose();
  };

  const handleSave = (ev) => {
    if (ev) {
      ev.preventDefault();
    }
    onSave({
      start: `${start.time}:00`,
      end: `${end.time}:00`,
      breaksData: breaksData.map(([startBreak, endBreak]) => [
        { time: moment(startBreak.time, 'HH:mm A').format(HOURS_MINUTES_SECONDS) },
        { time: moment(endBreak.time, 'HH:mm A').format(HOURS_MINUTES_SECONDS) }
      ]),
      day,
      week
    });
    onClose();
  };

  const defTimeConfig = {
    from: moment(start.time, 'HH:mm').format('HH:mm A'),
    to: moment(end.time, 'HH:mm').format('HH:mm A')
  };

  const timersBreak = [{
    labelName: 'Rast',
    time: start.time >= getTime(DEFAULT_BREAK_TIME_START) ? addTime(start.time, 1) : getTime(DEFAULT_BREAK_TIME_START),
    timeConfig: defTimeConfig
  },
  {
    removeText: 'Ta bort',
    time: start.time >= getTime(DEFAULT_BREAK_TIME_END) ? addTime(start.time, 2) : getTime(DEFAULT_BREAK_TIME_END),
    timeConfig: defTimeConfig
  }];

  const onRemove = (index) => {
    breaksData.splice(index, 1);
    setBreaksData([...breaksData]);
  };

  const addBreak = () => {
    if (breaksData.length === 0) {
      return setBreaksData([timersBreak]);
    }

    const [startBreak, endBreak] = clone(last(breaksData));
    // if time end more than end break time + 2 hours
    if (end.time > addTime(endBreak.time, 2)) {
      const timeConfig = {
        from: moment(endBreak.time, 'HH:mm').format('HH:mm A'),
        to: moment(end.time, 'HH:mm').format('HH:mm A')
      };

      return setBreaksData([
        ...breaksData,
        [{
          labelName: startBreak.labelName,
          time: addTime(endBreak.time, 2),
          timeConfig
        }, {
          ...endBreak,
          time: addTime(endBreak.time, 3),
          timeConfig
        }
        ]]);
    }
    return setBreaksData([
      ...breaksData,
      [{
        ...startBreak,
        time: addTime(endBreak.time, 1),
        timeConfig: {
          from: moment(endBreak.time, 'HH:mm').format('HH:mm A'),
          to: moment(end, 'HH:mm').format('HH:mm A')
        }
      }, {
        ...endBreak,
        time: moment(end.time, 'HH:mm').add('-30', 'minutes').format('HH:mm A'),
        timeConfig: {
          from: moment(end.time, 'HH:mm').format('HH:mm A'),
          to: `${moment(end.time, 'HH:mm').add('-30', 'minutes').format('HH:mm A')}`
        }
      }]
    ]);
  };

  const onChangeTime = (index, isStart, data) => {
    const breakTimeIndex = breaksData.findIndex((el, ind) => ind === index);
    if (isStart) {
      breaksData[breakTimeIndex][0].time = data;
    } else {
      breaksData[breakTimeIndex][1].time = data;
    }
    setBreaksData(breaksData);
  };

  const onChangeMainTime = (isStartTime, newTime) => {
    if (isStartTime) {
      setStart({ time: newTime });
    } else {
      setEnd({ time: newTime });
    }
  };

  const renderBreaks = () => breaksData.map(([start, end], index) => (
    <div key={index + start + end}>
      <TimePickerRow
        key={index}
        index={index}
        onChange={(isStart, data) => onChangeTime(index, isStart, data)}
        onRemove={() => onRemove(index)}
        timers={[start, end]}
        isAddGrayWrap
      />
    </div>
  ));

  return (
    <Popover
      preferPlace="row"
      className="Popover-big-radius"
      onOuterAction={handleClose}
      {...props}
      body={(
        <div className="Popover-dialog-sm">
          <form>
            <TimePickerRow
              timers={[start, end]}
              isAddGrayWrap={false}
              onChange={onChangeMainTime}
            />
            {renderBreaks()}
            <AddBreakLink onClick={addBreak}>
              <i className="far fa-sm fa-coffee" /> Lägg till rast
            </AddBreakLink>
            <DialogButtonsHorizontal>
              <DialogButton small gray onClick={handleClose}>Avbryt</DialogButton>
              <DialogButton small primary onClick={handleSave}>{isNew ? 'Öppna dag' : 'Spara'}</DialogButton>
            </DialogButtonsHorizontal>
          </form>
        </div>
      )}
    >
      {children}
    </Popover>
  );
};

export default ScheduleItemTimePopover;
