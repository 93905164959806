import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import TextareaAutosize from 'react-textarea-autosize';

import { createValidator, maxLength, phoneNumber, required, smsSenderValidation } from '@Utils/validation';
import SmsLengthLabel from '@Components/inputs/sms-length-label';
import AlertWithIcon from '@Components/ui/alert-with-icon';
import FormButtonsFooter from '@Components/ui/form-buttons-footer';
import { txt } from '@Utils/i18n-util';
import msg from './send-sms-modal.msg';

class SendSmsForm extends PureComponent {
  textArea = props => (
    <TextareaAutosize
      {...props.input}
      minRows={5}
      maxRows={20}
      className="form-control"
      autoFocus
    />
  );

  render() {
    const {
      message, handleSubmit, submitting, submitSucceeded, onClose, submitError
    } = this.props;
    const rateLimited = submitError?.response?.status === 429;

    return (
      <div>
        <form autoComplete="off" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="control-label">
              {txt(msg.lblSmsMessage)}
            </label>
            <Field
              component={this.textArea}
              className="form-control text-wrap"
              name="message"
            />
            <SmsLengthLabel text={message} />
          </div>
          {submitError && (
            <AlertWithIcon warning={rateLimited} error={!rateLimited}>
              {rateLimited
                ? 'Du har skickat för många SMS på kort tid. Försök igen om en liten stund.'
                : submitError.message}
            </AlertWithIcon>
          )}
          <FormButtonsFooter
            submitting={submitting}
            submitSucceeded={submitSucceeded}
            onClose={onClose}
            handleSubmit={handleSubmit}
            submitText={txt(msg.btnSend)}
          />
        </form>
      </div>
    );
  }
}

const validator = createValidator({
  sender: smsSenderValidation,
  msisdn: [required, phoneNumber],
  message: [required, maxLength(916)]
});

const selector = formValueSelector('send-sms-form');

const mapStateToProps = (state) => {
  return {
    message: selector(state, 'message')
  };
};

export default reduxForm({
  form: 'send-sms-form',
  validate: validator
})(connect(mapStateToProps)(SendSmsForm));
