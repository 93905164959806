import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  deleteBooking, clearAndCloseBKF, toggleBKFModal, setBKFProp, removeBookingCustomer,
  setBKFCustomer, deleteBookingSeries
} from '@State/bkf/actions';
import { bkfModals } from '@State/bkf/constants';
import { formatPhoneNumberE164 } from '@Utils/phone-util';
import { handleContactLink } from '@Utils/wk-embed-bridges';
import { getFeatures, getSmsEnabled } from '@State/selectors';
import {
  cancelBooking, refundBooking, sendBookingConfirmation, sendBookingReceipt,
  setClassBookingStatus, setClassBookingMaxSlots, setBookingSeriesPublished
} from '@State/booking-actions';
import { isBookingCancelled, isBookingReservation, isClassBooking, isSimpleBooking } from '@Utils/booking-util';
import { addToClipboard } from '@State/clipboard-actions';
import { txt } from '@Utils/i18n-util';
import ConfirmRemoveCustomer from './confirm-remove-customer';
import ConfirmDelete from './confirm-delete';
import ConfirmCancel from './confirm-cancel';
import ConfirmRefund from './confirm-refund';
import SendConfirmation from './send-confirmation';
import SendReceipt from './send-receipt';
import ConfirmCloseClass from './confirm-close-class';
import ConfirmPublishSeries from './confirm-publish-series';
import ConfirmUnpublishSeries from './confirm-unpublish-series';
import SetMaxSlots from './set-max-slots';
import msg from './booking-options.msg';

class BookingOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 'Menu',
      progress: false
    };
  }

  showMenu = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.setState({ view: 'Menu' });
  };

  showConfirmRemoveCustomer = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.setState({ view: 'ConfirmRemoveCustomer' });
  };

  confirmRemoveCustomer = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();

    this.setState({ progress: true });
    const { id, customer } = this.props;
    this.props.removeBookingCustomer(id, customer.customerId)
      .then(() => this.props.onClose())
      .catch(() => this.setState({ progress: false }));
  };

  showConfirmDelete = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.setState({ view: 'ConfirmDelete' });
  };

  deleteBooking = (options) => {
    this.setState({ progress: true });

    if (options.deleteSeries) {
      return this.props.deleteBookingSeries(this.props.series.seriesId)
        .catch(() => this.setState({ progress: false }));
    }
    return this.props.deleteBooking(this.props.id)
      .catch(() => this.setState({ progress: false }));
  };

  showConfirmCancel = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.setState({ view: 'ConfirmCancel' });
  };

  getCancelOptions = (ev) => {
    const form = ev.target;
    return {
      notifyBySms: form.notifyBySms?.checked,
      notifyByEmail: form.notifyByEmail?.checked,
      deleteBooking: form.deleteBooking?.checked,
      overrideCancellationPolicy: form.overrideCancellationPolicy?.checked
    };
  };

  cancelBooking = (options) => {
    this.setState({ progress: true });
    const { id, customer } = this.props;
    const customerIds = customer ? [customer.customerId] : [];
    this.props.cancelBooking({ bookingId: id, customerIds, options })
      .catch(() => this.setState({ progress: false }));
  };

  showConfirmRefund = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.setState({ view: 'ConfirmRefund' });
  };

  refundBooking = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();

    this.setState({ progress: true });
    const { customer } = this.props;
    this.props.refundBooking(this.props.id, customer?.customerBookingId)
      .catch(() => this.setState({ progress: false }));
  };

  showSendSms = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    const { customer } = this.props;
    this.props.onToggleSendSmsModal(true, {
      customerIds: [customer?.customerId]
    });
  };

  showSendConfirmation = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.setState({ view: 'SendConfirmation' });
  };

  sendConfirmation = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();

    const form = ev.target;
    const options = {
      sms: form.sms && form.sms.checked,
      email: form.email && form.email.checked
    };

    if (!options.sms && !options.email) return;

    this.setState({ progress: true });
    const { id, customer } = this.props;
    const customerIds = customer ? [customer.customerId] : [];

    this.props.onUpdateBooking()
      .then(() => this.props.sendBookingConfirmation({ bookingId: id, customerIds, options }))
      .then(() => this.props.onClose())
      .catch(() => this.setState({ progress: false }));
  };

  showSendReceipt = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.setState({ view: 'SendReceipt' });
  };

  sendReceipt = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();

    const form = ev.target;
    const payment = this.getPayment();

    const data = {
      paymentRef: payment.paymentRef,
      toName: form.name && form.name.value,
      toEmail: form.email && form.email.value
    };

    this.setState({ progress: true });
    this.props.sendBookingReceipt(data)
      .then(() => this.props.onClose())
      .catch(() => this.setState({ progress: false }));
  };

  printBooking = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.props.printBooking(this.props.id);
    this.props.onClose();
  };

  copyBooking = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.props.copyBooking(this.props.id, this.props.sourceResourceId);
  };

  moveBooking = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.props.moveBooking(this.props.id, this.props.sourceResourceId);
  };

  showEvents = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.props.onShowEvents(true);
  };

  registerPayment = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.props.onRegisterPayment();
    this.props.onClose();
  };

  showConfirmPublishSeries = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.setState({ view: 'ConfirmPublishSeries' });
  };

  publishSeries = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.props.setSeriesPublished(this.props.series, true);
    this.props.onClose();
  };

  showConfirmUnpublishSeries = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.setState({ view: 'ConfirmUnpublishSeries' });
  };

  unpublishSeries = (options) => {
    this.setState({ progress: true });
    return this.props.setSeriesPublished(this.props.series, false, options)
      .then(() => this.props.onClose())
      .catch(() => this.setState({ progress: false }));
  };

  showConfirmCloseClass = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.setState({ view: 'ConfirmCloseClass' });
  };

  closeClass = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.props.setClassClosed(this.props.id, true);
    this.props.onClose();
  };

  openClass = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.props.setClassClosed(this.props.id, false);
    this.props.onClose();
  };

  showSetMaxSlots = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.setState({ view: 'SetMaxSlots' });
  };

  setMaxSlots = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();

    this.setState({ progress: true });
    this.props.setMaxSlots(this.props.id, ev.target.maxSlots.value)
      .then(() => this.props.onClose())
      .catch(() => this.setState({ progress: false }));
  };

  onClose = (ev) => {
    ev && ev.preventDefault() && ev.stopPropagation();
    this.props.onClose();
  };

  getPayment = () => {
    return this.props.payments?.find(
      p => p.paymentType === 'Online' || p.paymentType === 'PrePaid'
    );
  };

  renderContent() {
    const { view, progress } = this.state;
    const {
      startTime, customer, customers, sale, series, smsEnabled,
      enablePos, enablePrepaid, classStatus, maxSlots
    } = this.props;

    const isClass = isClassBooking(this.props);
    const isBooking = isSimpleBooking(this.props);
    const isReservation = isBookingReservation(this.props);
    const isCancelled = isBookingCancelled(this.props);
    const isPastBooking = isBooking && moment().isAfter(startTime);
    const isLocked = isCancelled || isPastBooking || customer?.deleted;
    const hasCustomers = customers?.filter(c => c.status !== 'Cancelled').size > 0;
    const canDelete = isPastBooking || isCancelled || !hasCustomers;
    const payment = this.getPayment();
    const isPaid = payment?.paymentStatus === 'Paid';
    const isNotRefunded = payment?.paymentStatus !== 'Refunded' && payment?.paymentType === 'Online';
    const seriesNotPublished = series && !series.published;
    const seriesPublished = series && series.published;
    const bookingClosed = classStatus === 'Closed';

    if (view === 'ConfirmRemoveCustomer') {
      return <ConfirmRemoveCustomer onConfirm={this.confirmRemoveCustomer} onCancel={this.showMenu} progress={progress} />;
    }
    if (view === 'ConfirmDelete') {
      return <ConfirmDelete onConfirm={this.deleteBooking} onCancel={isReservation ? this.onClose : this.showMenu} progress={progress} />;
    }
    if (view === 'ConfirmCancel') {
      return <ConfirmCancel onConfirm={this.cancelBooking} onCancel={this.showMenu} progress={progress} customer={customer} />;
    }
    if (view === 'ConfirmRefund') {
      return <ConfirmRefund onConfirm={this.refundBooking} onCancel={this.showMenu} progress={progress} />;
    }
    if (view === 'SendConfirmation') {
      return <SendConfirmation onConfirm={this.sendConfirmation} onCancel={this.showMenu} progress={progress} customer={customer} />;
    }
    if (view === 'SendReceipt') {
      return <SendReceipt onConfirm={this.sendReceipt} onCancel={this.showMenu} progress={progress} customer={customer} />;
    }
    if (view === 'ConfirmCloseClass') {
      return <ConfirmCloseClass onConfirm={this.closeClass} onCancel={this.showMenu} />;
    }
    if (view === 'SetMaxSlots') {
      return <SetMaxSlots onConfirm={this.setMaxSlots} onCancel={this.showMenu} maxSlots={maxSlots} />;
    }
    if (view === 'ConfirmPublishSeries') {
      return <ConfirmPublishSeries onConfirm={this.publishSeries} onCancel={this.showMenu} />;
    }
    if (view === 'ConfirmUnpublishSeries') {
      return <ConfirmUnpublishSeries onConfirm={this.unpublishSeries} onCancel={this.showMenu} />;
    }

    return (
      <div>
        {isPastBooking && <p className="text-muted"><em>{txt(msg.txtTimePastWarning)}</em></p>}
        {customer?.deleted && <p className="text-muted"><em>{txt(msg.txtCustomerDeleted)}</em></p>}

        {this.renderMobileButtons()}
        {isBooking && smsEnabled && customer && customer.phoneNumber && (
        <button className="btn-option" onClick={this.showSendSms}>
          <i className="far fa-mobile" /> {txt(msg.btnSendSms)}
        </button>
        )}
        {isBooking && !isLocked && customer && (
        <button className="btn-option" onClick={this.showSendConfirmation}>
          <i className="far fa-paper-plane" /> {txt(msg.btnSendConfirmation)}
        </button>
        )}
        {isBooking && enablePos && enablePrepaid && !sale && customer && (
          <button className="btn-option" onClick={this.registerPayment}>
            <i className="far fa-circle-dollar-to-slot" /> {txt(msg.btnRegisterPayment)}
          </button>
        )}
        {isBooking && isPaid && (
        <button className="btn-option" onClick={this.showSendReceipt}>
          <i className="far fa-receipt" /> {txt(msg.btnSendReceipt)}
        </button>
        )}
        {isBooking && isNotRefunded && (
        <button className="btn-option" onClick={this.showConfirmRefund}>
          <i className="far fa-arrow-circle-left" /> {txt(msg.btnRefund)}
        </button>
        )}
        {series && !isLocked && seriesNotPublished && (
          <button className="btn-option" onClick={this.showConfirmPublishSeries}>
            <i className="fa fa-eye" /> {txt(msg.btnPublishSeries)}
          </button>
        )}
        {isClass && !isLocked && (
          <button className="btn-option" onClick={this.showSetMaxSlots}>
            <i className="fa fa-users" /> {txt(msg.btnSetMaxSlots)}
          </button>
        )}
        {isClass && !isLocked && !seriesNotPublished && bookingClosed && (
          <button className="btn-option mb2" onClick={this.openClass}>
            <i className="fa fa-lock-open" /> {txt(msg.btnOpenClass)}
          </button>
        )}
        {isClass && !isLocked && !seriesNotPublished && !bookingClosed && (
          <button className="btn-option mb2" onClick={this.showConfirmCloseClass}>
            <i className="fa fa-lock" /> {txt(msg.btnCloseClass)}
          </button>
        )}
        <button className="btn-option mb2" onClick={this.showEvents}>
          <i className="far fa-clock-rotate-left" /> {txt(msg.btnShowEvents)}
        </button>
        {!isReservation && (
        <button className="btn-option" onClick={this.printBooking}>
          <i className="fa fa-print" /> {txt(msg.btnPrintBooking)}
        </button>
        )}
        <button className="btn-option" onClick={this.copyBooking}>
          <i className="far fa-copy" /> {txt(msg.btnCopyBooking)}
        </button>
        {!isCancelled && !series && (
        <button className="btn-option" onClick={this.moveBooking}>
          <i className="fa fa-cut" /> {txt(msg.btnMoveBooking)}
        </button>
        )}
        {isBooking && !isCancelled && customer && (
        <button className="btn-option" onClick={this.showConfirmRemoveCustomer}>
          <i className="fa fa-arrow-right-arrow-left" /> {txt(msg.btnRemoveCustomer)}
        </button>
        )}
        {isBooking && !isLocked && customer && (
        <button className="btn-option btn-danger" onClick={this.showConfirmCancel}>
          <i className="fa fa-user-times" /> {txt(msg.btnCancelBooking)}
        </button>
        )}
        {canDelete && !seriesPublished && (
        <button className="btn-option btn-danger" onClick={this.showConfirmDelete}>
          <i className="fa fa-trash" /> {txt(msg.btnDeleteBooking)}
        </button>
        )}
        {seriesPublished && (
        <button className="btn-option btn-danger" onClick={this.showConfirmUnpublishSeries}>
          <i className="fa fa-eye-slash" /> {txt(msg.btnUnpublishSeries)}
        </button>
        )}
        <button className="btn-option mt2" onClick={this.onClose}>{txt(msg.btnCancel)}</button>
      </div>
    );
  }

  renderMobileButtons() {
    const { deviceType, customer } = this.props;
    const isMobile = deviceType === 'mobile';
    const phoneNumber = customer && customer.phoneNumber;
    const e164phoneNumber = formatPhoneNumberE164(phoneNumber);

    return isMobile && phoneNumber && (
      <a href={`tel:${e164phoneNumber}`} className="btn-option" onClick={handleContactLink}>
        <i className="fa fa-phone" /> {txt(msg.btnCall)} {phoneNumber}
      </a>
    );
  }

  render() {
    return (
      <div className="booking-options-container">
        <div className="booking-form-header mobile">
          <div className="cancel">
            <a href="#" onClick={this.onClose}>
              <i className="fa fa-chevron-left" /> {txt(msg.btnBack)}
            </a>
          </div>
          <h4 className="title">{txt(msg.lblOptionslTitle)}</h4>
          <div className="save" />
        </div>
        <div className="booking-options-content">
          {this.renderContent()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { bkf, mainViewState, posOrgs } = state;
  const features = getFeatures(state);

  return {
    posOrgs,
    id: bkf.get('id'),
    series: bkf.get('bookingSeries'),
    sourceResourceId: bkf.get('sourceResourceId'),
    startTime: bkf.get('startTime'),
    customer: bkf.get('customer'),
    customers: bkf.get('customers'),
    sale: bkf.get('sale'),
    payments: bkf.get('payments'),
    deviceType: mainViewState.get('deviceType'),
    smsEnabled: getSmsEnabled(state),
    enablePos: features.EnablePOS,
    enablePrepaid: features.EnablePrepaidBookings,
    status: bkf.get('status'),
    type: bkf.get('type'),
    bookedSlots: bkf.get('bookedSlots'),
    maxSlots: bkf.get('maxSlots'),
    classStatus: bkf.get('classStatus')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowEvents: () => dispatch(toggleBKFModal(bkfModals.events, true)),
    onRegisterPayment: () => dispatch(toggleBKFModal(bkfModals.payment, true)),
    onToggleSendSmsModal: (show, props) => dispatch(toggleBKFModal(bkfModals.sendSms, show, props)),
    sendBookingConfirmation: data => dispatch(sendBookingConfirmation(data)),
    sendBookingReceipt: data => dispatch(sendBookingReceipt(data)),
    printBooking: () => dispatch(toggleBKFModal(bkfModals.print, true)),
    copyBooking: (id, sourceResourceId) => {
      dispatch(addToClipboard(id, true, sourceResourceId));
      dispatch(clearAndCloseBKF());
    },
    moveBooking: (id, sourceResourceId) => {
      dispatch(addToClipboard(id, false, sourceResourceId));
      dispatch(clearAndCloseBKF());
    },
    removeBookingCustomer: (id, customerId) => {
      return dispatch(removeBookingCustomer(id, customerId))
        .then(() => dispatch(setBKFCustomer(null)));
    },
    cancelBooking: (data) => {
      return dispatch(cancelBooking(data))
        .then(() => dispatch(clearAndCloseBKF()));
    },
    deleteBooking: (id) => {
      return dispatch(deleteBooking(id))
        .then(() => dispatch(clearAndCloseBKF()));
    },
    deleteBookingSeries: (seriesId) => {
      return dispatch(deleteBookingSeries(seriesId))
        .then(() => dispatch(clearAndCloseBKF()));
    },
    refundBooking: (id, customerBookingId) => {
      return dispatch(refundBooking(id, customerBookingId))
        .then(() => dispatch(clearAndCloseBKF()));
    },
    setClassClosed: (id, closed) => {
      const status = closed ? 'Closed' : 'Open';
      dispatch(setBKFProp('classStatus', status));
      return dispatch(setClassBookingStatus(id, status));
    },
    setSeriesPublished: (series, published, options) => {
      dispatch(setBKFProp('bookingSeries', { ...series, published }));
      return dispatch(setBookingSeriesPublished(series.seriesId, published, options));
    },
    setMaxSlots: (id, maxSlots) => {
      dispatch(setBKFProp('maxSlots', parseInt(maxSlots)));
      return dispatch(setClassBookingMaxSlots(id, maxSlots));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingOptions);
