import moment from 'moment/moment';
import { isoWeekdays } from '@Utils/schedule-utils';

const dateFormat = 'YYYYMMDDTHHmmss[Z]';

export function getRruleDays() {
  return Array.from({ length: 7 }, (x, i) => {
    const weekDay = moment().weekday(i);
    return {
      day: isoWeekdays[weekDay.isoWeekday()].substring(0, 2),
      name: weekDay.format('dd')
    };
  });
}

function parseRruleString(rrule) {
  const rules = {};
  rrule?.split(';').forEach(pair => {
    const [key, value] = pair.split('=');
    rules[key] = value;
  });
  return rules;
}

export function getInitialValuesFromRruleString(rrule) {
  const rules = parseRruleString(rrule);

  const values = {
    repeat: rules.FREQ || 'WEEKLY',
    interval: rules.INTERVAL || 1,
    end: 'COUNT'
  };
  if (rules.BYDAY) {
    values.days = rules.BYDAY.split(',');
  }
  if (rules.COUNT) {
    values.end = 'COUNT';
    values.count = rules.COUNT || 1;
  }
  if (rules.UNTIL) {
    values.end = 'UNTIL';
    values.until = moment(rules.UNTIL, dateFormat).format('YYYY-MM-DD');
  }
  return values;
}

export function getRruleStringFromForm(values) {
  const { repeat, interval, days, end, until, count } = values || {};

  const rules = [
    `FREQ=${repeat || 'WEEKLY'}`,
    `INTERVAL=${interval || 1}`
  ];
  if (repeat === 'WEEKLY' && days) {
    rules.push(`BYDAY=${days.join(',')}`);
  }
  if (end === 'COUNT') {
    rules.push(`COUNT=${count || 1}`);
  }
  if (end === 'UNTIL') {
    rules.push(`UNTIL=${moment(until).add(1, 'day').utc().format(dateFormat)}`);
  }
  return rules.join(';');
}

const freqMap = {
  DAILY: 'Dagligen',
  WEEKLY: 'Veckovis',
  MONTHLY: 'Månadsvis',
  YEARLY: 'Årligen'
};

const freqTypeMap = {
  DAILY: 'dag',
  WEEKLY: 'vecka',
  MONTHLY: 'månad',
  YEARLY: 'år'
};

const daysMap = {
  MO: 'måndag',
  TU: 'tisdag',
  WE: 'onsdag',
  TH: 'torsdag',
  FR: 'fredag',
  SA: 'lördag',
  SU: 'söndag'
};

const dayOrder = ['MO', 'TU', 'WE', 'TH', 'FR', 'SA', 'SU'];

function groupConsecutiveDays(days) {
  const groups = [];
  let group = [days[0]];

  for (let i = 1; i < days.length; i++) {
    if (dayOrder.indexOf(days[i]) === dayOrder.indexOf(group[group.length - 1]) + 1) {
      group.push(days[i]);
    } else {
      groups.push(group);
      group = [days[i]];
    }
  }
  groups.push(group);

  return groups.map(group => group.length > 2
    ? `${daysMap[group[0]]}-${daysMap[group[group.length - 1]]}`
    : group.map(day => daysMap[day]).join(', ')
  ).join(', ');
}

export function getRruleDescription(rrule) {
  const rules = parseRruleString(rrule);
  const description = [freqMap[rules.FREQ] || rules.FREQ];

  if (rules.INTERVAL && rules.INTERVAL > 1) {
    const freqValue = freqTypeMap[rules.FREQ];
    const intervalValue = parseInt(rules.INTERVAL) === 2
      ? 'varannan'
      : `var ${rules.INTERVAL}:e`;
    description.push(`${intervalValue} ${freqValue}`);
  }
  if (rules.BYDAY) {
    const sortedDays = rules.BYDAY.split(',').sort((a, b) => dayOrder.indexOf(a) - dayOrder.indexOf(b));
    const groupedDays = groupConsecutiveDays(sortedDays);
    description.push(`på ${groupedDays}`);
  }
  if (rules.COUNT) {
    description.push(`vid ${rules.COUNT} tillfällen`);
  }
  if (rules.UNTIL) {
    description.push(`t.o.m. ${moment(rules.UNTIL).subtract(1, 'day').format('LL')}`);
  }
  return description.join(' ');
}
