import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useKeyPress from '@Hooks/useKeyPress';
import { addBookingCustomer, fetchBookingReminders, toggleBKFModal } from '@State/bkf/actions';
import { bkfModals } from '@State/bkf/constants';
import { CustomerType } from '@Utils/customer-util';
import { txt } from '@Utils/i18n-util';
import { getFeatures } from '@State/selectors';
import { queueBookingPayment } from '@State/pos-actions';
import { getSectionUrl, navigate } from '@Utils/navigate';
import ModalDialog from '@Components/dialogs/modal-dialog';
import { DialogTitleBtn } from '@Components/dialogs/dialog-styles';
import SearchCustomerModal from '@Components/customers/search-customer-modal';
import SendSmsModal from '@Components/customers/customer-detail/send-sms/send-sms-modal';
import Button from '@Components/ui/button';
import BookingClassCustomer from './booking-class-customer';
import BookingClassOptions from './booking-class-options';

import msg from './booking-class-modal.msg';

const BookingClassModal = ({ routeParams }) => {
  const dispatch = useDispatch();
  const { EnablePOS } = useSelector(getFeatures);
  const formVisible = useSelector(({ cf }) => cf.get('formVisible'));
  const bookingId = useSelector(state => state.bkf.get('id'));
  const services = useSelector(state => state.bkf.get('services'));
  const startTime = useSelector(state => state.bkf.get('startTime'));
  const customers = useSelector(state => state.bkf.get('customers'));
  const bookedSlots = useSelector(state => state.bkf.get('bookedSlots'));
  const maxSlots = useSelector(state => state.bkf.get('maxSlots'));
  const payments = useSelector(state => state.bkf.get('payments'));
  const sales = useSelector(state => state.bkf.get('sales'));

  const [selected, setSelected] = useState([]);
  const [showSendSms, setShowSendSms] = useState(false);
  const [showAddCustomer, setShowAddCustomer] = useState(false);

  const filteredCustomers = customers?.filter(c => c.status !== 'Cancelled');

  const hasCustomers = filteredCustomers?.size > 0;
  const noneSelected = selected.length === 0;
  const allSelected = !noneSelected && selected.length === filteredCustomers.size;

  useEffect(() => {
    dispatch(fetchBookingReminders(bookingId));

    document.body.classList.add('print-hidden');

    return () => {
      document.body.classList.remove('print-hidden');
    };
  }, []);

  const onSelect = (customerId) => {
    if (selected.includes(customerId)) {
      setSelected(selected.filter(s => s !== customerId));
    } else {
      setSelected([...selected, customerId]);
    }
  };

  const onSelectAll = (ev) => {
    ev.stopPropagation();
    setSelected(allSelected ? [] : filteredCustomers.keySeq().toArray());
  };

  const onCloseForm = () => dispatch(toggleBKFModal(bkfModals.class, false));

  const onAddCustomer = (ev) => {
    ev.preventDefault();
    ev.target.blur();
    setShowAddCustomer(true);
  };

  const onCustomerSelected = (customer) => {
    dispatch(addBookingCustomer(bookingId, customer));
    setShowAddCustomer(false);
  };

  const onSendToPos = (ev) => {
    ev.preventDefault();
    if (EnablePOS) {
      const customer = filteredCustomers.find(c => selected[0] === c.customerId);
      dispatch(queueBookingPayment({ bookingId, customer }));
    }
    navigate(getSectionUrl(routeParams, 'pos'));
  };

  useKeyPress(27, () => {
    if (!showSendSms && !formVisible) {
      onCloseForm();
    }
  });

  if (formVisible) {
    return null;
  }

  if (showSendSms) {
    return (
      <SendSmsModal
        customerIds={selected}
        onClose={() => setShowSendSms(false)}
      />
    );
  }

  if (showAddCustomer) {
    return (
      <SearchCustomerModal
        routeParams={routeParams}
        onSelected={onCustomerSelected}
        onCancel={() => setShowAddCustomer(false)}
        scope={[CustomerType.Person]}
      />
    );
  }

  return (
    <ModalDialog
      focusTrapPaused
      closeOnClickOutside={false}
      contentSize="xl"
      alignTop
    >
      <div className="booking-class-modal-dialog">
        <div className="pull-right">
          <DialogTitleBtn onClick={onCloseForm}>{txt(msg.closeBtnLabel)}</DialogTitleBtn>
        </div>
        <div className="print-visible">
          <h4 className="mt0 mb1">
            {txt(msg.participants)}
            <span>({bookedSlots || 0}/{maxSlots})</span>
          </h4>
          <p className="mb0">{services.map(s => s.name).join(', ')}</p>
          <p className="text-muted">{startTime.format('LLLL')}</p>
        </div>
        <div className="booking-class-modal-content" tabIndex={0}>
          {hasCustomers ? (
            <div className="booking-class-customer-header">
              <button type="button" className="btn btn-default" onClick={onSelectAll}>
                <div className="select">
                  <input type="checkbox" checked={allSelected} onChange={onSelectAll} />
                  {txt(msg.selectAll)}
                </div>
              </button>
              <div className="options">
                {EnablePOS && (
                  <Button small primary disabled={noneSelected} onClick={onSendToPos}>
                    <i className="far fa-cash-register mr1" />Ta betalt
                  </Button>
                )}
                <button type="button" className="btn btn-default" onClick={onAddCustomer}>
                  <i className="fa fa-user-plus mr1" />{txt(msg.addCustomer)}
                </button>
                <BookingClassOptions
                  bookingId={bookingId}
                  customerIds={selected}
                  disabled={noneSelected}
                  onSetCustomerIds={setSelected}
                  onShowSendSms={setShowSendSms}
                />
              </div>
            </div>
          ) : (
            <button type="button" className="btn btn-default" onClick={onAddCustomer}>
              <i className="fa fa-user-plus mr1" />{txt(msg.addCustomer)}
            </button>
          )}
          <div className="print-visible">
            {hasCustomers ? filteredCustomers.valueSeq().map(customer => {
              return (
                <BookingClassCustomer
                  key={customer.customerId}
                  customer={customer}
                  bookingId={bookingId}
                  payments={payments}
                  sales={sales}
                  selected={selected.includes(customer.customerId)}
                  onSelect={() => onSelect(customer.customerId)}
                />
              );
            }) : (
              <p className="text-center mt6 mb4">{txt(msg.noCustomers)}</p>
            )}
          </div>
        </div>
        <div className="text-right">
          <button type="button" className="btn btn-default" onClick={onCloseForm}>
            {txt(msg.closeBtnLabel)}
          </button>
        </div>
      </div>
    </ModalDialog>
  );
};

export default BookingClassModal;
