import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { setBKFProp, toggleBKFModal } from '@State/bkf/actions';
import { isBookingNew } from '@Utils/booking-util';
import { bkfModals } from '@State/bkf/constants';
import { txt } from '@Utils/i18n-util';
import { getInitialValuesFromRruleString, getRruleDescription } from './recurring-util';
import BookingRecurringForm from './booking-recurring-form';
import msg from './booking-form.msg';

const BookingRecurring = ({ id, recurrenceRule, bookingIds, onShowSeries, recurring, toggleRecurring }) => {
  const isNew = isBookingNew(id);

  const initialValues = useMemo(() => {
    return getInitialValuesFromRruleString(recurrenceRule);
  }, [recurrenceRule]);

  const onToggleRecurring = (ev) => {
    ev.preventDefault();
    ev.currentTarget.blur();
    toggleRecurring(!recurring);
  };

  if (isNew && !recurring) {
    return (
      <button className="booking-form-block button" onClick={onToggleRecurring}>
        <i className="fa fa-repeat" /> {txt(msg.recurringBtnLabel)}
      </button>
    );
  }

  if (isNew && recurring) {
    return (
      <div className="booking-form-block form">
        <BookingRecurringForm initialValues={initialValues} />
        <div className="text-right mt1">
          <button className="btn-label" tabIndex={-1} onClick={onToggleRecurring}>Avbryt</button>
        </div>
      </div>
    );
  }

  if (recurring && recurrenceRule) {
    return (
      <div className="booking-form-block bottom-part one-line" onClick={() => onShowSeries()}>
        <div className="block-buttons" onClick={ev => ev.stopPropagation()}>
          <button className="btn-label" tabIndex={-1} onClick={() => onShowSeries()}>
            Visa pass
          </button>
        </div>
        <div>
          <i className="fa fa-repeat mr1" />
          Återkommande vid {bookingIds.length} tillfällen
          {/* getRruleDescription(recurrenceRule) */}
        </div>
      </div>
    );
  }

  return null;
};

const mapStateToProps = (state) => {
  const { bkf } = state;
  const { recurrenceRule, bookingIds } = bkf.get('bookingSeries') || {};

  return {
    id: bkf.get('id'),
    recurring: bkf.get('recurring'),
    recurrenceRule,
    bookingIds
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onShowSeries: () => dispatch(toggleBKFModal(bkfModals.series, true)),
    toggleRecurring: (recurring) => dispatch(setBKFProp('recurring', recurring))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingRecurring);
